html {
  background-color: rgb(2, 1, 32);
}
* {
  margin: 0;
  padding: 0;
}

.align-mid {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.mbody {
  width: 100%;
  display: flex;
  height: 100%;
}
